.sw-loader__wrapper {
  position: relative;
}
.sw-loader__wrapper--center {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sw-loader__wrapper .sw-loader__holder {
  width: 70%;
  height: 70%;
  right: 15%;
  margin-top: 15%;
  position: relative;
  transform: rotate(-45deg);
}
.sw-loader__wrapper .sw-loader__holder .rect {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to right, #f79b18, #009245, #7d4e0c, #05324a, #164289, #f79b18, #f79b18, #009245);
  background-position: 0% 50%;
  background-size: 1000% 1000%;
  overflow: hidden;
  -webkit-animation: moveGradient 15s infinite;
          animation: moveGradient 15s infinite;
}

@-webkit-keyframes moveGradient {
  to {
    background-position: 100% 50%;
  }
}

@keyframes moveGradient {
  to {
    background-position: 100% 50%;
  }
}
