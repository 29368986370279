@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&family=Heebo:wght@300;400;500;600;700&display=swap');
body,html,#root {
    position: relative;
    height: 100%;
    font-family: Cairo,Heebo;
    font-weight: 400;
}
a,a:focus,a:hover{
    text-decoration: none !important;
    color: inherit;;
}
body{
    padding: 0px;
    margin: 0px;
    background-color: #e2e2e2 !important;
}
header{
    background-color: #eef0ef;
}
header .logo{
    height: 70px;
}
footer{
    background-color: #05324a;
    color: #fff;
    font-size: 12px;
}
footer img{
    height: 26px;
}
.navbar-light .navbar-nav .nav-link {
    color:#164289 !important;
    font-size: 19px;
    padding-left: 15px !important;
}

.social{
    flex-grow: inherit !important;
}
.social img{
    width: 35px;
    height: 35px;
    transition: 0.3s;
}
.social img:hover {
    filter: saturate(10) sepia(0.4);
}


.navbar-expand-lg .social .navbar-nav .nav-link{
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
    position: relative;
    transition: .3s;
}
.collapse:not(.social) .navbar-nav .nav-link:after{
    position: absolute;
    bottom:-5px;
    content: " ";
    left: 50%;
    border-bottom: 3px solid #fcbf49;
    z-index: 99;
    width: 0%;
    transition: .3s;
}
.navbar-expand-lg .navbar-nav .nav-link:hover::after ,
.navbar-expand-lg .navbar-nav .nav-link:focus::after {
    width: 100%;
    left: 0px;
}

@media(max-width: 1200px) {
    .navbar-light .navbar-nav .nav-link {
        font-size: inherit;;
        text-align: center;
    }
    .navbar-expand-lg .social .navbar-nav {
        display: block;
        text-align: center;
    }
    .navbar-expand-lg .social .navbar-nav .nav-link {
        display: inline-block !important;
        width: inherit;;
    }
}
.main{
    min-height: calc(100% - 122px);
}


.slider{
    position: absolute !important;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
 }
.slider .carousel-inner{
    height: 100%;
}

@media(max-width: 991px) {
    header .logo{
        height: 50px;
    }
    .main{
        min-height: calc(100% - 102px);
        height: auto !important;
    }

    .carousel-item.h-100{
        height: 320px!important;
    }


    .slider .carousel-inner{
        height: inherit;
        overflow: inherit;
    }
}

.input-group-text{
    background-color: #113c53 !important;
    border-radius: 0px !important;
    color: #fff !important;
}
.input-group{
    height: 45px;
}
.form-control,
.form-select{
    border-radius: 0px !important;
}
[dir='rtl'] .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-right: -1px;
}
[dir='rtl'] .form-select{
    background-position: left 0.75rem center !important;
    padding: 0.375rem 0.375rem 0.375rem 0.75rem;
}

.form-select:focus,
.form-control:focus{
    box-shadow: none !important;
}
.mbox{
    background-color: rgba(255,255,255,0.83);
    text-align: center;
    padding:20px;
    color: #003049;
    transition: .3s;
    border: 2px solid transparent;
    height: 100%;
}

.mbox img{
    max-width: 100px;
}
.mbox p{
    font-weight: 500;
    margin-top: 10px;
}

.mbox2 img{
    max-width: inherit;
    max-height: 100px;
}
.mbox .btn-link{
    display: inline-block;
    border: 1px solid #003049;
    border-radius: 10px;
    color: inherit;
    text-decoration: none;
    padding: 2px 10px;
    transition: .3s;
}
.mbox:hover{
    background-color: #fff;
    border-color: rgba(0, 48, 73, 0.7);
}
.mbox:hover .btn-link{
    background-color:  #003049;
    color: #fff;
}
.main-icons{
    background-color: rgba(252, 191, 73, 0.83)
}
.main-icons img{
    height: 44px;
}
.main-icons .col-lg div{
    transition: .3s;
    padding:16px 0px;
}
.main-icons .col-lg div:hover {
    background-color: rgba(252, 191, 73, 1);
    color: #fff;
    border-left: 1px solid #113c53;
    border-right: 1px solid #113c53;
    cursor: pointer;
}

.main-icons .col-lg div p{
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500;
}
.qnews {
    background-color: rgba(5, 50, 74, 0.8)
}
@media(max-width: 991px) {
    .mbox{
        background-color: #f7f7f7;
        border-color: rgba(0, 48, 73, 0.2);
    }

}
.color1{
    color: #f4ba4b;
}
.color2{
    color:#003049;
}
.color3{
    color:#164289;
}
.color4{
    color: #1b465c;
}
.bold{
    font-weight: 700 !important;
}
.normal{
    font-weight: 400 !important;
}
.qnews .title {
    border-left: 2px solid #fff;
    flex:none;
}
.marquee a{
    color: #fff;
    display: inline-block;
    margin-left: 30px;
}
.marquee a:hover{
    color: #f4ba4b;
}
.aboutus.nav{
    background-color: #fff;
    justify-content:space-around;
}
.aboutus .nav-item{
    display: inline-flex;
    flex-grow: 1 ;
}
.aboutus .nav-item a{
    width: 100%;
    text-align: center;
}
.aboutus .nav-item .nav-link{
    color: #003049;
    font-size: 19px;
    padding: 10px 15px;
}
.aboutus .nav-item .nav-link.active{
    background-color: #fcbf49;
}
@media (min-width: 1200px){
    .col-20{
        width: 20% !important;
    }
}
@media(max-width: 991px){
    .aboutus .nav-item .nav-link{
        font-size: 17px;
    }
}
.about-title{
    display: inline-block;
    border-bottom: 4px solid #fcbf49;
}
.searchInput{
    -moz-box-shadow:    inset 0 0 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.1);
    box-shadow:         inset 0 3px 10px -4px rgba(0,0,0,0.3);
}
.newsItem{
    border-bottom: 1px solid #1b465c;
}
.eventdate{
    background-color: #05324a;
    color: #ffffff;
}

.eventdate .month{
    font-size: 9px;
}
.react-calendar{
    width: 100% !important;
    border: 0px !important;
    font-family: inherit !important;
}
.react-calendar__month-view__weekdays{
    padding: 0px ;
    margin: 0px;
}
.react-calendar__month-view__weekdays__weekday{
    border-bottom: 0px !important;
    color: #1b465c;
    font-size: 20px;
    font-weight: 700;
    flex: 0 0 14.3% !important;
}
.react-calendar__tile p{
    font-size: 9px;
    margin-bottom: 0px;
}
abbr[data-bs-original-title], abbr[title]{
    text-decoration: none !important;
}
.react-calendar__tile{
    box-shadow: 2px 3px 10px rgba(0,0,0,0.2);
    height: 60px;
    margin: 10px !important;
    flex: 0 0 11.5% !important;
    flex-grow:  1 !important;
}
.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus{
    background-color: transparent !important;
}
.react-calendar__navigation__label__labelText{
    color: #1b465c;
    font-size: 25px;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend,.react-calendar__tile{
    color: #1b465c !important;
}

.react-calendar__tile--now{
    background-color: inherit !important;
    color: #1b465c !important;
}
.react-calendar__tile--active{
    background-color: #1b465c !important;
    color: #fff !important;
}
.react-calendar__tile{
    position: relative;
}
.react-calendar__tile p:after{
    content: " ";
    font-size: 30px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #1b465c;

}
.react-calendar__tile--active p:after{
    background-color: #fff !important;
}
.fs-7{
    font-size: .7rem;
}
.eventBtn {
    color: #fff !important;
    padding: 3px 14px;
    font-size: 14px !important;
    box-shadow: 0px 2px 12.39px 8.61px rgba(0, 0, 0, 0.07),inset 0px 0px 98px 0px rgba(0, 0, 0, 0);
}
.eventday{
    font-size: 100px;
    line-height: 1;
}
.btn2{
    background-color: #1b465c !important;
    color: #fff !important;
    border-radius: 0px !important;
}
.serviceCat .list-group-item:before{
    content: "»";
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
}
.serviceCat .list-group-item{
    transition: .3s;
}
.serviceCat .list-group-item:hover,
.list-group-item.active{
    background-color: #1b465c !important;
    color: #fff;
    cursor: pointer;
}
.list-group-item.active{
    background-color: #05324a !important;
    border-color: transparent !important;
}
.serviceInfo{
    background: rgb(245,217,163);
    background: linear-gradient(180deg, rgba(245,217,163,1) 19%, rgba(204,170,108,1) 100%);
}
.accordion-button{
    border-radius: 0px !important;
    padding: 10px 20px !important;
    background-color: #143c4d !important;
    color: #fff !important;
}
.accordion-button::after{
    color: #fff !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;    margin-right: 10px;
}
.accordion-button:focus,
.accordion-button:hover
button:focus,
button:hover{
    box-shadow: none !important;
}
.searchLink{
    position: relative;
}
.searchLink:after{
    position: absolute;
    display: inline-block;
    content: "+";
    width: 20px;
    height: 20px;
    left: 10px;
    top: 5px;
}
.bg-yallow{
    background-color: #f9c35c !important;
    color: #143c4d !important;
}
.borderTable thead tr,
.borderTable tbody tr{
    background-color: #fff;
    box-shadow: 2px 3px 8px rgba(0,0,0,0.1);
    margin-bottom: 10px;
    vertical-align: middle;
}
.btn-outline-dark{
    border-color: #143c4d !important;
    color: #143c4d !important;
}
.btn-outline-dark:hover {
    color: #fff !important;
    background-color: #143c4d !important;;
}
.r-0{
    border-radius: 0px !important;
}
.text-justify{
    text-align: justify;
}
.rdt_TableHead{
    color: #1b465c !important;
    font-size: 1.3rem !important;
    font-weight: bold !important;
}
@media(max-width: 786px){
    .rdt_TableHead{
        font-size: 1rem !important;
    }
}
.rdt_TableRow{
    color: #1b465c !important;
    font-size: 17px;
}

.customPage img,
.customPage iframe{
    max-width: 100% !important;
}
.customPage table{
    width: 100%;
}
.customPage table tr{
    border-bottom: 1px solid rgba(20, 60, 77, 0.39);
}
.customPage table td{
    padding: 5px ;
}

.carousel-indicators{
    margin-bottom: 40px;
}
.carousel-control-next, .carousel-control-prev{
    height: 100px;
    top: calc(50%  - 50px);
}
.darkBtn{
    cursor: pointer;
    width: 100% !important;
    text-align: right;
    color: #143c4d !important;
    border:0px !important;
    border-radius: 0px !important;
}
.tags .nav-item{
    margin-left: 20px !important;
}
.tags .nav-item:last-child{
    margin-left: 0px !important;
}
.active.darkBtn{
    background: #143c4d !important;
    color: #ffffff !important;
    border:0px !important;
}
.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active{
    z-index: inherit;
}
.breadcrumb-item+.breadcrumb-item::before {
float: right;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

